<template>
    <div class="page" v-if="isDataLoaded">
        <div class="banner">
            <van-swipe v-if="!isEmpty(detail.otherImage)" class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(image, index) in detail.otherImage" :key="index">
                    <img :src="image | qiniuImageView(1, 750, 750)" />
                </van-swipe-item>
            </van-swipe>
            <img v-else :src="detail.mainImage | qiniuImageView(1, 750, 750)" alt="">
        </div>
        <div class="del-title" data-flex="cross:center">
            <div class="f-fs14 f-fwb price" data-flex-box="1">
                <span class="f-fs14 f-fwb">￥</span>
                <span class="f-fs22">{{detail.price}}</span>
            </div>
            <div class="f-fc10 f-fs14">库存：{{detail.stockNum || 0}}</div>
        </div>
        <div class="del-name">
            <div class="txt1 f-fs16 f-fwb">{{detail.title}}</div>
            <div class="txt2 f-fs14 f-fc10">{{detail.title}}</div>
        </div>

        <div class="description">
            <div class="name">商品详情</div>
            <div class="info" v-html="replaceImg(detail.description)"></div>
        </div>
    </div>
</template>

<script>
import mix1 from '@/mixins/page'
import { uiShowLoading, uiHideLoading, uiAlert, uiConfirm } from '@/utils/ui'
import { delayAsync } from '@/utils/common'
import { Button, Tab, Tabs, Progress, Empty } from 'vant'
import MgPopup from '@/components/common/mg-popup'

export default {
    mixins: [mix1],
    components: {
        MgPopup,
        [Button.name]: Button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [Progress.name]: Progress,
        [Empty.name]: Empty,

    },
    data() {
        return {
            searchForm: {
                id: 0,
            },
            detail: {}
        }
    },
    methods: {
        toUrl(url) {
            this.$router.push(url)
        },
        async loadData() {

            uiShowLoading()
            await delayAsync(500)

            const promise = this.apiGet('goods.detail', this.searchForm)

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }
            this.isDataLoaded = true
            this.detail = apiResult.obj
            // this.asyncTile(apiResult.obj.title)
        },
    },
    mounted() {

    },
    created() {
        let query = this.$route.query
        this.searchForm.id = query.id
        this.loadData()
    }
}
</script>

<style lang="less">
.rd {
    .van-progress {
        width: 200px;
    }
}

.van-empty__image {
    width: 500px;
    height: 500px;
}
</style>
<style lang="less" scoped>
.page {
    .banner {
        background: #fff;
        box-sizing: border-box;

        img {
            width: 750px;
            height: 750px;
            margin-bottom: -6px;
        }
    }

    .del-title {
        background: #fff;
        width: 750px;
        padding: 30px;
        box-sizing: border-box;

        .price {
            color: #000;
            font-size: 21px;
        }

    }
    .del-name{
        background: #fff;
        width: 750px;
        margin-top: 10px;
        div{
            padding: 20px;
            box-sizing: border-box;
        }
        .txt1{
            border-bottom: solid 1px #f1f1f1;
        }
    }   
    .description{
        background: #fff;
        width: 750px;
        margin-top: 10px;
        .name{
            padding: 30px 20px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: bold;
        }
        .info{
            padding: 0px 20px 30px;
            box-sizing: border-box;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .rt-img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

}
</style>